import React from "react"

const Section01Question: React.FC = () => {

    return (
        <section className="container max-w-4xl 2xl:max-w-6xl mx-auto pt-14 md:pt-32 lg:pt-20 2xl:pt-28 px-8">
            <div className="shadow-md">
                <div className="tab w-full overflow-hidden border-t">
                    <input className="absolute opacity-0 " id="tab-multi-1" type="checkbox" name="tabs" />
                    <label className="block p-5 leading-normal cursor-pointer" htmlFor="tab-multi-1">
                    ¿A quién va dirigido Evofinder®? 
                    </label>
                    <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-indigo-500 leading-normal">
                        <p className="p-5">
                        El sistema es altamente configurable, desde laboratorios pequeños en fase inicial de acreditación, hasta laboratorios  acreditados de alto volúmen diario  de operación crítica.
                        </p>
                    </div>
                </div>
                <div className="tab w-full overflow-hidden border-t">
                    <input className="absolute opacity-0" id="tab-multi-2" type="checkbox" name="tabs" />
                    <label className="block p-5 leading-normal cursor-pointer" htmlFor="tab-multi-2">
                    ¿Está validado el equipo Evofinder®? 
 
                   </label>
                    <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-indigo-500 leading-normal">
                        <p className="p-5">
                        Sí, el equipo cuenta con los métodos de operación y validación  para la acreditación ISO/IEC 17025  ANAB. Durante  la validación en México se demostró que EVOFINDER® es una tecnología ampliamente superior en especificidad y rendimiento, comparado a los otros sistemas preexistentes. 
                        </p>
                    </div>
                </div>
                <div className="tab w-full overflow-hidden border-t">
                    <input className="absolute opacity-0" id="tab-multi-3" type="checkbox" name="tabs" />
                    <label className="block p-5 leading-normal cursor-pointer" htmlFor="tab-multi-3">
                    ¿Existen licencias ocultas que condicionan el funcionamiento del equipo a un pago anual? 
                    </label>
                    <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-indigo-500 leading-normal">
                        <p className="p-5">
                        Evofinder® está totalmente libre de pagos anuales por concepto de conectividad, licenciamiento,  garantizando su operación permanente.
                        </p>
                    </div>
                </div>
                <div className="tab w-full overflow-hidden border-t">
                    <input className="absolute opacity-0" id="tab-multi-4" type="checkbox" name="tabs" />
                    <label className="block p-5 leading-normal cursor-pointer" htmlFor="tab-multi-4">
                    ¿A quién pertenecen las bases de datos ?
                    </label>
                    <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-indigo-500 leading-normal">
                        <p className="p-5">
                        La base de datos es propiedad del laboratorio o institución  que adquiere el sistema Evofinder®,  no existe restricción de acceso como licencias ocultas o por conectividad. 
                        </p>
                    </div>
                </div>
                <div className="tab w-full overflow-hidden border-t">
                    <input className="absolute opacity-0" id="tab-multi-5" type="checkbox" name="tabs" />
                    <label className="block p-5 leading-normal cursor-pointer" htmlFor="tab-multi-5">
                    ¿Puedo acreditar mi laboratorio con el sistema Evofinder®? 
                   </label>
                    <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-indigo-500 leading-normal">
                        <p className="p-5">
                        Sí, Evofinder® es un equipo desarrollado con normas internacionales, si usted cuenta o está en proceso de acreditación, Evofinder es una excelente inversión, debido a que es un sistema acreditado y con un bajo nivel de obsolescencia, comparado con otros sistemas. 
                        </p>
                    </div>
                </div>
                <div className="tab w-full overflow-hidden border-t">
                    <input className="absolute opacity-0" id="tab-multi-6" type="checkbox" name="tabs" />
                    <label className="block p-5 leading-normal cursor-pointer" htmlFor="tab-multi-6">
                    ¿Evofinder® cuenta con soporte técnico en México?
                   </label>
                    <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-indigo-500 leading-normal">
                        <p className="p-5">
                        Evofinder® cuenta con soporte las 24 hrs de forma remota y un tiempo de respuesta de 2 a 24 hrs, in situ,  por un técnico o especialista en cualquier parte de México. 

                        </p>
                    </div>
                </div>
                <div className="tab w-full overflow-hidden border-t">
                    <input className="absolute opacity-0" id="tab-multi-7" type="checkbox" name="tabs" />
                    <label className="block p-5 leading-normal cursor-pointer" htmlFor="tab-multi-7">
                    ¿Qué es el Virtual Comparison Microscope – VCM?                   </label>
                    <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-indigo-500 leading-normal">
                        <p className="p-5">
                        Es el análisis digital de la topografía de la superficie 3D escaneada, se conoce como Microscopía de Comparación Virtual (VCM).  Evofinder® VCM,  permite la revisión y comparación de las características individuales  a un nivel de detalle muy exacto y definido, lo que permite un ahorro sustancial de tiempo en la investigación.

                        </p>
                    </div>
                </div>
                <div className="tab w-full overflow-hidden border-t">
                    <input className="absolute opacity-0" id="tab-multi-8" type="checkbox" name="tabs" />
                    <label className="block p-5 leading-normal cursor-pointer" htmlFor="tab-multi-8">
                    ¿Dónde se guarda mi información?
                    </label>
                    <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-indigo-500 leading-normal">
                        <p className="p-5">
                        Toda la información generada se guarda de forma local, con altos estándares de seguridad,  con respaldos automáticos, asegurando la máxima integridad y disponibilidad del sistema. 
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section01Question;
