import * as React from "react"
// import ChatComponents from "../components/chat"
import Layout from "../components/layout"
import Section01Question from "../components/pregunta/Section01"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <div className="hidden lg:flex">
    </div>
    <Seo title={" Preguntas frecuentes y recursos adicionales "}
        description={"Obtén respuestas a tus preguntas sobre el sistema Evofinder. Encuentra la información que necesitas en nuestros recursos."}
     />
    
    <Section01Question />
  </Layout>
)

export default IndexPage
